import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access: null,
    refresh: null,
    initCompleted: false,
    clientId: null,

    applicant: null,
    coApplicant: null,
  },
  mutations: {
    setRefresh(state, payload) {
        state.refresh = payload;
    },
    initIsCompleted(state) {
      state.initCompleted = true;
    },
    setClientId(state, payload) {
      state.clientId = payload;
    },

    setApplicant(state, payload) {
      state.applicant = payload;
    },
  },
  getters: {
    getRefresh(state) {
      return state.refresh;
    },
    getInitCompleted(state) {
      return state.initCompleted;
    },
    getClientId(state) {
      return state.clientId;
    },

    getApplicant(state) {
      return state.applicant;
    },
  }
})
