import axios from "axios";
// import store from "@/store/index";

let baseURL = "https://api.bankari.sk/api/";

if (process.env.NODE_ENV === "production")
    baseURL = "https://api.bankari.sk/api/";

const _axios = axios.create({
    baseURL: baseURL,
    withCredentials: true,
});

_axios.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("access_token");

    if (token && token !== "undefined")
        config.headers.Authorization = `Bearer ${token}`;

    return config;
});

_axios.interceptors.response.use(response => response, async error => {
    if (error.response.status === 401) {
        try {
            const refreshToken = localStorage.getItem("refresh_token");

            let data = null;

            if (refreshToken) {
                data = {refresh: refreshToken};
            }

            await axios.post(baseURL + "auth/token/refresh/", data);

            return axios.request(error.config);
        } catch (e) {
            if (error.response.status === 401) {
                //Keď aj refresh vrati 401, chceme dať logout - aby sa vymazali cookies
                await axios.post(baseURL + "auth/logout/");
            }
        }
    }

    return Promise.reject(error);
});

export default _axios;